document.addEventListener('DOMContentLoaded', function () {
    const form = document.querySelector('form.contact');
    const flash = document.querySelector('.massege-info');
    form.addEventListener('submit', function (event) {
        event.preventDefault();

        grecaptcha.ready(function() {
            grecaptcha.execute('6LeAbXkpAAAAADX_YHckM68QC_-CgXvTqszVo-y2', {action: 'submit'}).then(function(token) {
                var person = document.querySelector('input[name="person"]:checked').nextElementSibling.textContent;
                var contactMethod = document.querySelector('input[name="contact"]:checked').nextElementSibling.textContent;
                var socialNetwork = document.querySelector('input[name="socialnet"]:checked').nextElementSibling.textContent;
                var name = form.querySelector('input[name="username"]').value;
                var phone = form.querySelector('input[name="phone"]').value;
                var message = form.querySelector('textarea[name="text"]').value;
                var country = form.querySelector('input[name="country"]').value;
                var city = form.querySelector('input[name="city"]').value;

                const chatIds = ['-1001981122473'];
                const apiUrl = 'https://api.telegram.org/bot6393241495:AAGOPUE3gqAtMJQQMM_jbBN5ecw75n-yFLA/sendMessage';
                chatIds.forEach(chatId => {
                    fetch(apiUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            chat_id: chatId,
                            text: `📌📩 New massage!\n\nI am: ${person}\nI want: ${contactMethod}\nMethod of communication: ${socialNetwork}\n\nName: ${name}\nPhone: ${phone}\nMessage: ${message}\nCountry: ${country}\nCity: ${city}`,
                        }),
                    })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        form.querySelector('input[name="username"]').value = "";
                        form.querySelector('input[name="phone"]').value = "";
                        form.querySelector('textarea[name="text"]').value = "";
                        form.querySelector('input[name="country"]').value = "";
                        form.querySelector('input[name="city"]').value = "";
                        setTimeout(() => {
                            flash.style.left = "20px";
                        }, 500);
                        setTimeout(() => {
                            flash.style.left = "-200px";
                        }, 5000);
                        fbq('track', 'Lead');
                    });
                });
            });
        });
    });
});

document.addEventListener('DOMContentLoaded', function () {
    const forms = document.querySelectorAll('.form-franchise');
    const flashes = document.querySelectorAll('.massege-info');

    forms.forEach((form, index) => {
        form.addEventListener('submit', function (event) {
            event.preventDefault();

            grecaptcha.ready(function() {
                grecaptcha.execute('6LeAbXkpAAAAADX_YHckM68QC_-CgXvTqszVo-y2', {action: 'submit'}).then(function(token) {
                    var name = form.querySelector('input[name="username"]').value;
                    var phone = form.querySelector('input[name="phone"]').value;
                    var email = form.querySelector('input[name="email"]').value;
                    var country = form.querySelector('input[name="country"]').value;
                    var city = form.querySelector('input[name="city"]').value;

                    const chatIds = ['-1001981122473'];
                    const apiUrl = 'https://api.telegram.org/bot6393241495:AAGOPUE3gqAtMJQQMM_jbBN5ecw75n-yFLA/sendMessage';
                    chatIds.forEach(chatId => {
                        fetch(apiUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                chat_id: chatId,
                                text: `📌📩 Franchise\n\nI am: ${name}\nPhone number: ${phone}\nEmail: ${email}\nCountry: ${country}\nCity: ${city}`,
                            }),
                        })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            form.querySelector('input[name="username"]').value = "";
                            form.querySelector('input[name="phone"]').value = "";
                            form.querySelector('input[name="email"]').value = "";
                            form.querySelector('input[name="country"]').value = "";
                            form.querySelector('input[name="city"]').value = "";
                            setTimeout(() => {
                                flashes[index].style.left = "20px";
                            }, 500);
                            setTimeout(() => {
                                flashes[index].style.left = "-200px";
                            }, 5000);
                            fbq('track', 'Lead');
                        });
                    });
                });
            });
        });
    });
});

document.addEventListener('DOMContentLoaded', function () {
    const formConsultation = document.querySelectorAll('.form-consultation');
    const flashMessage = document.querySelectorAll('.massege-info');

    formConsultation.forEach((form, index) => {
        form.addEventListener('submit', function (event) {
            event.preventDefault();

            grecaptcha.ready(function () {
                grecaptcha.execute('6LeAbXkpAAAAADX_YHckM68QC_-CgXvTqszVo-y2', { action: 'submit' }).then(function (token) {
                    var name = form.querySelector('input[name="username"]').value;
                    var phone = form.querySelector('input[name="phone"]').value;
                    var country = form.querySelector('input[name="country"]').value;

                    const chatIds = ['-1001981122473'];
                    const apiUrl = 'https://api.telegram.org/bot6393241495:AAGOPUE3gqAtMJQQMM_jbBN5ecw75n-yFLA/sendMessage';
                    chatIds.forEach(chatId => {
                        fetch(apiUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                chat_id: chatId,
                                text: `📌📩 Consultation\n\nI am: ${name}\nPhone number: ${phone}\nCountry: ${country}`,
                            }),
                        })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            form.querySelector('input[name="username"]').value = "";
                            form.querySelector('input[name="phone"]').value = "";
                            form.querySelector('input[name="country"]').value = "";
                            document.getElementById("consultation-popup").style.visibility = "hidden";
                            setTimeout(() => {
                                flashMessage[index].style.left = "20px";
                            }, 500);
                            setTimeout(() => {
                                flashMessage[index].style.left = "-200px";
                            }, 5000);
                            fbq('track', 'Lead');
                        });
                    });
                });
            });
        });
    });
});

document.addEventListener('DOMContentLoaded', function () {
    const formRequest = document.querySelectorAll('.form-request');
    const flashMessages = document.querySelectorAll('.massege-info');

    formRequest.forEach((form, index) => {
        form.addEventListener('submit', function (event) {
            event.preventDefault();

            grecaptcha.ready(function () {
                grecaptcha.execute('6LeAbXkpAAAAADX_YHckM68QC_-CgXvTqszVo-y2', { action: 'submit' }).then(function (token) {
                    var name = form.querySelector('input[name="username"]').value;
                    var phone = form.querySelector('input[name="phone"]').value;
                    var country = form.querySelector('input[name="country"]').value;

                    const chatIds = ['-1001981122473'];
                    const apiUrl = 'https://api.telegram.org/bot6393241495:AAGOPUE3gqAtMJQQMM_jbBN5ecw75n-yFLA/sendMessage';
                    chatIds.forEach(chatId => {
                        fetch(apiUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                chat_id: chatId,
                                text: `📌📩 Request\n\nI am: ${name}\nPhone number: ${phone}\nCountry: ${country}`,
                            }),
                        })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            form.querySelector('input[name="username"]').value = "";
                            form.querySelector('input[name="phone"]').value = "";
                            form.querySelector('input[name="country"]').value = "";
                            setTimeout(() => {
                                flashMessages[index].style.left = "20px";
                            }, 500);
                            setTimeout(() => {
                                flashMessages[index].style.left = "-200px";
                            }, 5000);
                            fbq('track', 'Lead');
                        });
                    });
                });
            });
        });
    });
});
